<template>
  <v-main>
    <div class="fill-height">
      <div class="background-element background-image" :style="'background-image: url(' + backgroundUrl + ');'"></div>
      <div class="background-gradient background-element"></div>

      <v-container class="fill-height">
        <div class="pa-0 col-12 col-sm-8 col-md-8 col-lg-6 col-xl-4" style="margin: auto;">

          <div>
            <v-card color="c_background" style="width: 100%">
              <v-card-title class="pb-0">Bitte bestätigen, um fortzufahren.</v-card-title>
              <v-card-text>

                <v-form v-model="valid" ref="form">

                  <v-checkbox v-model="privacyPolicy" :rules="checkboxRules" hide-details="auto">
                    <template v-slot:label>
                      <div>
                        Ich willige ein in die
                        <a target="_blank" href="/privacy-policy" @click.stop>Datenschutzerklärung</a>
                      </div>
                    </template>
                  </v-checkbox>

                  <v-checkbox v-model="customerInfo" class="mt-2" :rules="checkboxRules" hide-details="auto">
                    <template v-slot:label>
                      <div>
                        Ich habe die
                        <a target="_blank" href="/customer-information" @click.stop>Kundeninformation</a>
                        zur Kenntnis genommen
                      </div>
                    </template>
                  </v-checkbox>

                </v-form>

                <div class="d-flex justify-space-between mt-6">
                  <v-btn color="" outlined @click="reject">Ablehnen</v-btn>
                  <v-btn color="primary" @click="submit">Bestätigen</v-btn>
                </div>

              </v-card-text>
            </v-card>
          </div>

        </div>
      </v-container>

    </div>
  </v-main>
</template>

<script>
import axios from "axios";
import firebase from "firebase/compat";

export default {
  name: "AcceptTerms",
  components: {},
  data() {
    return {
      backgroundUrl: require("@/assets/images/climber_1080.jpg"),
      privacyPolicy: false,
      customerInfo: false,
      valid: false,
      checkboxRules: []
    };
  },
  computed: {},
  mounted() {

  },
  methods: {
    reject() {
      this.$cookies.remove("roy-spitzner-custom-token");
      axios.defaults.headers.common["Authorization"] = null;
      this.$store.commit("setUserIsLoggedIn", false);
      this.$router.push("/");
    },
    submit() {
      this.checkboxRules = [
        v => v || "Bitte bestätigen"
      ];

      // let the DOM update
      this.$nextTick(() => {
        if (this.$refs.form.validate()) {
          this.$store.dispatch("putRequest", ["accept-terms", "", this.submitCallback]);
        }
      });
    },
    submitCallback() {
      this.$router.push("/home")
    }
  }
};
</script>

<style scoped>

.background-element {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.background-image {
  filter: saturate(80%) contrast(50%) brightness(80%);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow: hidden;
}

.background-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7427171552214635) 10%, rgba(0, 0, 0, 0) 55%);
}

</style>